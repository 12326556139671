<template>
  <div class="container" ref="mapRef"></div>
</template>
<script>
import { loadScript } from '@/utils/util'
export default {
  props: {
    params: {
      type: Array,
      default: () => {}
    },
    resize: {
      type: Number,
      default: 0
    }
  },
  watch: {
    params(newVal, oldVal) {
      const isEmpty = !newVal || newVal.length === 0
      if (isEmpty) {
        this.myChart && this.myChart.dispose()
        this.myChart = null
        return
      }
      this.setCityData(newVal)
    },
    resize(newVal, oldVal) {
      this.myChart && this.myChart.resize()
    }
  },
  data() {
    return {
      cityData: [],
      cityGeoCoordMap: {},
      chartOption: {
        color: ['#91cc75'],
        title: {
          show: false,
          left: 'left',
          text: '于万智驾车足迹',
          textStyle: {
            fontSize: 14
          },
          padding: [0, 0, 0, 0]
        },
        tooltip: {
          trigger: 'item'
        },
        bmap: {
          center: [104.114129, 37.550339],
          zoom: 3,
          roam: false,
          mapStyle: {
            styleJson: [
              {
                featureType: 'country',
                elementType: 'all',
                stylers: {
                  visibility: 'off'
                }
              },
              {
                featureType: 'water',
                elementType: 'all',
                stylers: {
                  color: '#d1d1d1'
                }
              },
              {
                featureType: 'land',
                elementType: 'all',
                stylers: {
                  color: '#f3f3f3'
                }
              },
              {
                featureType: 'railway',
                elementType: 'all',
                stylers: {
                  visibility: 'off'
                }
              },
              {
                featureType: 'highway',
                elementType: 'all',
                stylers: {
                  color: '#fdfdfd'
                }
              },
              {
                featureType: 'highway',
                elementType: 'labels',
                stylers: {
                  visibility: 'off'
                }
              },
              {
                featureType: 'arterial',
                elementType: 'geometry',
                stylers: {
                  color: '#fefefe'
                }
              },
              {
                featureType: 'arterial',
                elementType: 'geometry.fill',
                stylers: {
                  color: '#fefefe'
                }
              },
              {
                featureType: 'poi',
                elementType: 'all',
                stylers: {
                  visibility: 'off'
                }
              },
              {
                featureType: 'green',
                elementType: 'all',
                stylers: {
                  visibility: 'off'
                }
              },
              {
                featureType: 'subway',
                elementType: 'all',
                stylers: {
                  visibility: 'off'
                }
              },
              {
                featureType: 'manmade',
                elementType: 'all',
                stylers: {
                  color: '#d1d1d1'
                }
              },
              {
                featureType: 'local',
                elementType: 'all',
                stylers: {
                  color: '#d1d1d1'
                }
              },
              {
                featureType: 'arterial',
                elementType: 'labels',
                stylers: {
                  visibility: 'off'
                }
              },
              {
                featureType: 'boundary',
                elementType: 'all',
                stylers: {
                  color: '#b9ab77'
                }
              },
              {
                featureType: 'building',
                elementType: 'all',
                stylers: {
                  color: '#d1d1d1'
                }
              },
              {
                featureType: 'label',
                elementType: 'all',
                stylers: {
                  visibility: 'off'
                }
              }
            ]
          },
          mapOptions: {
            enableMapClick: false
          }
        },
        series: [
          {
            type: 'effectScatter',
            coordinateSystem: 'bmap',
            data: [],
            symbolSize: function (val) {
              return 50
            },
            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke'
            },
            tooltip: {
              formatter: '{b}'
            },
            label: {
              position: 'inside',
              formatter: '\n{cityStyle|{b}}\n{numStyle|{@value}}\n',
              show: true,
              rich: {
                cityStyle: {
                  align: 'center',
                  lineHeight: 30
                },
                numStyle: {
                  fontSize: 10,
                  align: 'center'
                }
              }
            },
            labelLayout: function () {
              return {
                align: 'center',
                moveOverlap: 'shiftY'
              }
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: '#333'
            },
            emphasis: {
              scale: true
            },
            zlevel: 1
          }
        ]
      }
    }
  },
  mounted() {
    this.loadChart()
  },
  methods: {
    setCityData(data) {
      const echarts = window.echarts
      // 判断图表库是否加载完成
      if (!echarts) {
        setTimeout(() => {
          this.setCityData(data)
        }, 500)
        return
      }
      const myData = data ? data.filter((p) => p.cityName) : []
      myData.forEach((p) => {
        const cityName = p.cityName
        let flag = cityName.endsWith('市')
        if (flag) {
          p.cityName = cityName.substr(0, cityName.length - 1)
        }
        flag = cityName.endsWith('地区')
        if (flag) {
          p.cityName = cityName.substr(0, cityName.length - 2)
        }
      })
      this.cityGeoCoordMap = {}
      this.cityData = myData.map((p) => {
        return {
          name: p.cityName,
          value: p.count
        }
      })
      myData.forEach((p) => {
        this.cityGeoCoordMap[p.cityName] = [p.lng, p.lat]
      })
      this.chartOption.series[0].data = this.convertData(
        this.cityData
          .sort(function (a, b) {
            return b.value - a.value
          })
          .slice(0, 6)
      )

      if (!this.myChart) {
        this.myChart = echarts.init(this.$refs.mapRef)
      }
      this.myChart.setOption(this.chartOption)
    },
    convertData(data) {
      const res = []
      for (let i = 0; i < data.length; i++) {
        const geoCoord = this.cityGeoCoordMap[data[i].name]
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].value)
          })
        }
      }
      return res
    },
    loadChart() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.initMap()
        })
      })
    },
    initMap() {
      loadScript('//cdn.jsdelivr.net/npm/echarts@5/dist/echarts.min.js', () => {
        loadScript('//cdn.jsdelivr.net/npm/echarts@5/dist/extension/bmap.min.js', () => {
          console.log('echarts finished')
          const echarts = window.echarts
          this.myChart = echarts.init(this.$refs.mapRef)
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
}
</style>
<style>
.anchorBL {
  display: none;
}
</style>
